import { Navigate } from "react-router-dom";
import { isPermissionChecked, isApplicationTypeChecked } from "utils"
const RoutePermissionCheck = ({ children, permissionDto, routeKey, checkedChild }: any) => {
    const isAuthenticated = checkedChild ?  isApplicationTypeChecked(permissionDto?.data?.permissionsData || [], routeKey) : isPermissionChecked(permissionDto?.data?.permissionsData || [], routeKey)?.isChecked; // Example, replace this with your authentication logic

    if (isAuthenticated) {
        return children;
    } else {
        // Redirect to login page if not authenticated
        return <Navigate to="/page-not-found" />;
    }
};

export default RoutePermissionCheck