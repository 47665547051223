import state from "./state.json";
export const pageSizeList = [10, 20, 30, 40, 50];
export const cardViewPageSizeList = [12, 24, 36, 48, 60];

export const listCode = state;

export const aboutUsLink = "https://smartfreightcentre.org/en/about-sfc/about-us/";

export const millionT = 1000000
export const evEmissionT = 22.5
export const adminUrl = 'app/'
export const actUrl = 'track/'
export const nodeUrl = 'node/'
export const benchmarkUrl = 'app/'

export const evDashboardEnable = false

export enum Provider {
    evNetwork = 4,
    optimus = 5
}

export const emailDetail = {
    "email": process.env.REACT_APP_DUMMY_EMAIL,
    "password": process.env.REACT_APP_DUMMY_PASSWORD
}

export const defaultQuarter = 0

export const companySlug = {
    pep: "PEP",
    adm: "ADM",
    lw: "LW",
    tql: "TQL"
}

export const scopeSlug = {
    scope1: "scope1",
    scope2: "scope2",
    scope3: "scope3",
}

export enum valueConstant {
    DATE_FORMAT = "MM-DD-YYYY",
    STATUS_ACTIVE = 1,
    STATUS_DEACTIVATE = 2
}

export const evProductCode = "EV"
export const rdProductCode = "RD"

export const instructions = [
    {
        fileicon: "/images/xlsFile.svg",
        text: "Only excel files are accepted for upload. Ensure that your file has the '.xlsx' extension.",
    },
    {
        fileicon: "/images/xlsFile.svg",
        text: "Each field in your excel file should be labeled appropriately to indicate the type of data it contains.",
    },
    {
        fileicon: "/images/fileListIcon.svg",
        text: "Remove any unnecessary data.",
    },
    {
        fileicon: "/images/fileListIcon.svg",
        text: "All data should be in correct format.",
    },
    {
        fileicon: "/images/toDo.svg",
        text: " Required fields: Origin City, Origin State, Destination City, Destination State, SCAC, and RPM.",
    }
];

export const routeKey:any = {
    Visibility: "VIS",
    Recommendations: "REC",
    Manage: "MAN",
    Segmentation: "SEG",
    Benchmarks: "BEN",
    UserManagement: "USM",
    RoleManagement: "USM",
    DataManagement: "DAM",
    ApplicationManagement: "APM",
    AlternativeFuel: "AMS",
    ModalShift: "AMS",
    CarrierShift: "CAS",
    AdministratorAccess: "ADA",
    ApplicationAccess: "APA",
    EvDashboard: "EVD",
    BidPlanning: "BIP"
}

export const monthOption = [
    {
        value: "",
        quarter: 0,
        label: "All Month"
    },
    {
        value: 1,
        quarter: 1,
        label: "January"
    }, {
        value: 2,
        quarter: 1,
        label: "February"
    }, {
        value: 3,
        quarter: 1,
        label: "March"
    }, {
        value: 4,
        quarter: 2,
        label: "April"
    }, {
        value: 5,
        quarter: 2,
        label: "May"
    }, {
        value: 6,
        quarter: 2,
        label: "June"
    }, {
        value: 7,
        quarter: 3,
        label: "July"
    }, {
        value: 8,
        quarter: 3,
        label: "August"
    }, {
        value: 9,
        quarter: 3,
        label: "September"
    }, {
        value: 10,
        quarter: 4,
        label: "October"
    }, {
        value: 11,
        quarter: 4,
        label: "November"
    }, {
        value: 12,
        quarter: 4,
        label: "December"
    }]