import axios from "axios";
import { actUrl } from "constant"

/**
 * Regional Services
 */

// Function to fetch region table data
const divisionTableDataApi = async (userData: any,) => {
    try {
        const response = await axios.post(`${actUrl}get-division-table-data`, userData);
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Function to fetch region graph data
const divisionGraphDataApi = async (userData: any,) => {
    try {
        const response = await axios.post(`${actUrl}get-division-graph-data`, userData);

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

// Function to fetch region graph data
const divisionListApi = async () => {
    try {
        const response = await axios.get(`${actUrl}get-division-list`);

        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};



// Function to fetch region overview detail
const getDivisionOverviewDetailApi = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}get-division-overview`, userData);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

const getDivisionRegionComparisonDataApi = async (userData: any): Promise<ApiResponse> => {
    try {
        return (await axios.post(`${actUrl}get-division-region-comparison-data`, userData)).data;
    } catch (error: any) {
        throw error;
    }
};

const laneBreakdownDetailForDivisionApi = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}get-by-division-lane-breakdown`, userData);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

const businessUnitEmissionDivisionApi = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}division-buisness-unit-data`, userData);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}


const businessUnitEmissionDivisionListApi = async (userData: any) => {
    try {
        const response = await axios.post(`${actUrl}get-business-unit-emission-division-list`, userData);
        return response?.data;
    } catch (error: any) {
        throw (error);
    }
}

// Object containing all regional services
const divisionService = {
    divisionTableDataApi,
    divisionGraphDataApi,
    divisionListApi,
    getDivisionOverviewDetailApi,
    getDivisionRegionComparisonDataApi,
    laneBreakdownDetailForDivisionApi,
    businessUnitEmissionDivisionListApi,
    businessUnitEmissionDivisionApi
};

// Export the regional service object
export default divisionService;
