import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./dashboardService";
import { getErrorMessage, isCancelRequest } from "utils";
import { DashboardInterface } from "./dashboardInterface";

// Define the shape of the state

const initialState: DashboardInterface = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isLoadingDashboardMatrics: false,
  dashboardMatricsData: null,
  isLoadingFuelConsumption: false,
  fuelConsumptionGraphData: null,
  isLoadingFuelEmissions: false,
  fuelEmissionsGraphData: null,
  isLoadingFuelConsumptionByPeriod: false,
  fuelConsumptionDataByPeriod: null
};

// Async Thunks for fetching sustain service data
export const getDashboardMatricsData = createAsyncThunk("get/scope1/dashboard/matrics",
  async (userData: any, thunkApi) => {
    try {
      return await dashboardService.getDashboardMatrics(
        userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getFuelConsumptionData = createAsyncThunk("get/scope1/fuel/consumption",
  async (userData: any, thunkApi) => {
    try {
      return await dashboardService.fuelConsumptionGraphApi(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getFuelEmissionsData = createAsyncThunk("get/scope1/fuel/emissions",
  async (userData: any, thunkApi) => {
    try {
      return await dashboardService.fuelEmissionsGraphApi(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getFuelConsumptionByPeriodData = createAsyncThunk("get/scope1/fuel/consumption/by/period",
  async (userData: any, thunkApi) => {
    try {
      return await dashboardService.fuelConsumptionByPeriodApi(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);


export const scopeOneDashboardReducer = createSlice({
  name: "scope-one-dashboard",
  initialState,
  reducers: {
    resetScopeOneDashboard: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDashboardMatricsData.pending, (state) => {
        state.isLoadingDashboardMatrics = true;
        state.dashboardMatricsData = null
      })
      .addCase(getDashboardMatricsData.fulfilled, (state, action) => {
        state.dashboardMatricsData = action.payload;
        state.isLoadingDashboardMatrics = false;
      })
      .addCase(getDashboardMatricsData.rejected, (state, action) => {
        state.isLoadingDashboardMatrics = isCancelRequest(action?.payload);
        state.dashboardMatricsData = null
      })
      .addCase(getFuelConsumptionData.pending, (state) => {
        state.isLoadingFuelConsumption = true;
        state.fuelConsumptionGraphData = null
      })
      .addCase(getFuelConsumptionData.fulfilled, (state, action) => {
        state.fuelConsumptionGraphData = action.payload;
        state.isLoadingFuelConsumption = false;
      })
      .addCase(getFuelConsumptionData.rejected, (state, action) => {
        state.isLoadingFuelConsumption = isCancelRequest(action?.payload);
        state.fuelConsumptionGraphData = null
      })
      .addCase(getFuelEmissionsData.pending, (state) => {
        state.isLoadingFuelEmissions = true;
        state.fuelEmissionsGraphData = null
      })
      .addCase(getFuelEmissionsData.fulfilled, (state, action) => {
        state.fuelEmissionsGraphData = action.payload;
        state.isLoadingFuelEmissions = false;
      })
      .addCase(getFuelEmissionsData.rejected, (state, action) => {
        state.isLoadingFuelEmissions = isCancelRequest(action?.payload);
        state.fuelEmissionsGraphData = null
      })
      .addCase(getFuelConsumptionByPeriodData.pending, (state) => {
        state.isLoadingFuelConsumptionByPeriod = true;
        state.fuelConsumptionDataByPeriod = null
      })
      .addCase(getFuelConsumptionByPeriodData.fulfilled, (state, action) => {
        state.fuelConsumptionDataByPeriod = action.payload;
        state.isLoadingFuelConsumptionByPeriod = false;
      })
      .addCase(getFuelConsumptionByPeriodData.rejected, (state, action) => {
        state.isLoadingFuelConsumptionByPeriod = isCancelRequest(action?.payload);
        state.fuelConsumptionDataByPeriod = null
      })
  },
});

export const { resetScopeOneDashboard } = scopeOneDashboardReducer.actions;
export default scopeOneDashboardReducer.reducer;
