import axios from "axios";
import { actUrl } from "constant"


/**
 * Retrieves vendor table data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const vendorTableDataGet = async (userData: any): Promise<ApiResponse> => {
    try {
        return (await axios.post(`${actUrl}get-Vendor-table-data`, userData))
            .data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves carrier overview data using a GET request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */

const getCarrierOverview = async (userData: any): Promise<ApiResponse> => {
    try {
        return (await axios.post(`${actUrl}get-carrier-overview`, userData)).data;
    } catch (error: any) {

        throw error;
    }
};

/**
 * Retrieves lane breakdown data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const getLaneBreakdown = async (userData: any): Promise<ApiResponse> => {
    try {
        return (await axios.post(`${actUrl}get-lane-breakdown`, userData)).data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves lane carrier list data using a GET request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const getLaneCarrierList = async (): Promise<ApiResponse> => {
    try {
        return (await axios.get(`${actUrl}get-lane-carrier`)).data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves lane carrier comparison data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const getLaneCarrierCompaire = async (userData: any): Promise<ApiResponse> => {
    try {
        return (await axios.post(`${actUrl}get-vendor-comparison`, userData)).data;
    } catch (error: any) {
        throw error;
    }
};

/**
 * Retrieves lane carrier table data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */
const laneCarrierTableDataApi = async (userData: any): Promise<ApiResponse> => {
    try {
        return (await axios.post(`${actUrl}get-lane-carrier-table-data`, userData)).data;
    } catch (error: any) {
        throw error;
    }
};

const getRegionCarrierComparisonTable = async (userData: any): Promise<ApiResponse> => {
    try {
        return (await axios.post(`${actUrl}get-carrier-region-comparison-table-data`, userData)).data;
    } catch (error: any) {
        throw error;
    }
};


/**
 * A collection of functions related to vendor-related API calls.
 */
const vendorService = {
    vendorTableDataGet,
    getCarrierOverview,
    getLaneBreakdown,
    getLaneCarrierList,
    getLaneCarrierCompaire,
    laneCarrierTableDataApi,
    getRegionCarrierComparisonTable
};

// Export the vendorService object for use in other parts of the application
export default vendorService;
