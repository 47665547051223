import axios from "axios";
import { nodeUrl } from "constant"


// Fetch scope1 dashboard matrics data
const getTimeMappingApi = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}time-mapping-list?year=${userData?.year}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

// Service object that exposes functions
const commonDataService = {
  getTimeMappingApi,
};

// Export the service object
export default commonDataService;
