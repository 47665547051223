import axios from "axios";
import { nodeUrl } from "constant"


// Fetch scope1 dashboard matrics data
const getDashboardMatrics = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}matrix-data?year=${userData?.year}&period_id=${userData?.periodId}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const fuelConsumptionGraphApi = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}fuel-consumption-graph?year=${userData?.year}&period_id=${userData?.periodId}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const fuelEmissionsGraphApi = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}fuel-emissions-graph?year=${userData?.year}&period_id=${userData?.periodId}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

const fuelConsumptionByPeriodApi = async (userData: any) => {
  try {
    const response = await axios.get(`${nodeUrl}fuel-consumption-by-period-graph?year=${userData?.year}&period_id=${userData?.periodId}`);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

// Service object that exposes functions
const dashboardService = {
  getDashboardMatrics,
  fuelConsumptionGraphApi,
  fuelEmissionsGraphApi,
  fuelConsumptionByPeriodApi
};

// Export the service object
export default dashboardService;
