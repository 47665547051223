import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/redux.hooks';
import { logoutPost , applicationType} from 'store/auth/authDataSlice';
import { useAuth } from 'auth/ProtectedRoute';
import { getAdminUrl, getBaseUrl } from "utils";
/**
 * 
 * @returns All the states and functions for DashboardView
 */

const SidebarController = () => {
    // Define constant 
    const location = useLocation();
    const dispatch = useAppDispatch()
    const {isSidebarOpen} = useAppSelector((state: any) => state.commonData)
    const { loginDetails, scopeType, userProfile, applicationTypeStatus } = useAppSelector((state: any) => state.auth);

    const navigate = useNavigate()

    // Logout function
    const handleLogout = () => {
        dispatch(logoutPost() as any);
        navigate("/")
    }
    const dataCheck = useAuth();
    const handleToggleApplication = (e: any) => {
        dispatch(applicationType(e))
        navigate(!e ? getBaseUrl(loginDetails?.data, scopeType, userProfile?.data) : getAdminUrl(loginDetails?.data?.permissionsData || []))
    }
    //  All the states and functions returned
    return {
        dataCheck,
        handleLogout,
        applicationTypeStatus,
        location,
        loginDetails,
        isSidebarOpen,
        dispatch,
        scopeType,
        handleToggleApplication,
        userProfile
    };
};

export default SidebarController;