import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonDataService from "./commonService";
import { getErrorMessage, isCancelRequest } from "utils";
import { CommonDataInterface } from "./commonInterface";

// Define the shape of the state

const initialState: CommonDataInterface = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isLoadingTimeMappingList: false,
  timeMappingList: null,
};

// Async Thunks for fetching sustain service data
export const getScope1TimeMappingList = createAsyncThunk("get/scope1/time/mapping/list",
  async (userData: any, thunkApi) => {
    try {
      return await commonDataService.getTimeMappingApi(userData);
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const scopeOneCommonDataReducer = createSlice({
  name: "scope-one-common-data",
  initialState,
  reducers: {
    resetScopeOneCommonData: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getScope1TimeMappingList.pending, (state) => {
        state.isLoadingTimeMappingList = true;
        state.timeMappingList = null
      })
      .addCase(getScope1TimeMappingList.fulfilled, (state, action) => {
        state.timeMappingList = action.payload;
        state.isLoadingTimeMappingList = false;
      })
      .addCase(getScope1TimeMappingList.rejected, (state, action) => {
        state.isLoadingTimeMappingList = isCancelRequest(action?.payload);
        state.timeMappingList = null
      })
  },
});

export const { resetScopeOneCommonData } = scopeOneCommonDataReducer.actions;
export default scopeOneCommonDataReducer.reducer;
