import axios from "axios";
import { actUrl, nodeUrl } from "constant"

/**
 * Common Services
 */

// Function to fetch emission filter dates

const getFiltersDate = async (): Promise<ApiResponse> => {
  try {
    return (await axios.get(`${actUrl}graph-filters-dates`)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to post region intensity data
const postRegionIntensity = async (
  userData: any
): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${actUrl}get-region-intensity-yearly`, userData)).data;
  } catch (error: any) {
    throw error;
  }
};

const getDivisions = async (): Promise<ApiResponse> => {
  try {
    return (await axios.get(`${actUrl}get-division-list`)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch regions
const getRegions = async (data: { division_id: number | string }): Promise<ApiResponse> => {
  try {
    let url = `${actUrl}get-regions`
    if (data?.division_id) {
      url = `${actUrl}get-regions?division_id=${data?.division_id}`
    }
    return (await axios.get(url)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to post region level glide path data
const postRegionLevelGlidePath = async (userData: any): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${actUrl}get-region-reduction`, userData)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch project count using API
const getProjectCountApi = async (
  userData: any
): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${actUrl}get-project-count`, userData)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to save site url using API
const saveUrlApi = async (
  payload: any
): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${actUrl}save-url`, payload)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch content using API
const getCmsContentApi = async (
  payload: any
): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${actUrl}get-cms-content`, payload)).data;
  } catch (error: any) {
    throw error;
  }
};

const getNotificationListing = async (
  payload: any
): Promise<ApiResponse> => {
  try {
    return (await axios.get(`${actUrl}get-user-notifications`)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to save site url using API
const sendMessageBotApi = async (
  payload: any,
): Promise<ApiResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_CHARTBOAT}`,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return response?.data;
  }
  catch (error: any) {
    throw (error);
  }
};

// Time Period

// Function to fetch content using API
const getTimePeriodApi = async (
  payload: any
): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${actUrl}time-mapping-list`, payload)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch content using API
const getOnboardQuestionListApi = async (
  payload: any
): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${nodeUrl}get-onboard-question-list`, payload)).data;
  } catch (error: any) {
    throw error;
  }
};

// Function to fetch content using API
const addUpdateQuestionAnswereApi = async (
  payload: any
): Promise<ApiResponse> => {
  try {
    return (await axios.post(`${nodeUrl}add-update-question-answere`, payload)).data;
  } catch (error: any) {
    throw error;
  }
};

// 

// Object containing all common services
const commonService = {
  getFiltersDate,
  getProjectCountApi,
  postRegionIntensity,
  getDivisions,
  getRegions,
  postRegionLevelGlidePath,
  saveUrlApi,
  getCmsContentApi,
  getNotificationListing,
  sendMessageBotApi,
  getTimePeriodApi,
  getOnboardQuestionListApi,
  addUpdateQuestionAnswereApi
};

// Export the common service object
export default commonService;
