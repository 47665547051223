import GreenInsightRoute from './routes/GreenInsightRoute';
const App = () => {
  return (
    <div data-testid="app-component">
      <GreenInsightRoute />
    </div>

  );
}

export default App;
